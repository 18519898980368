import React from 'react';
import { Container, Col } from 'reactstrap';
import PropTypes from 'prop-types';

const About = props => (
  <div id="about" className="section">
    <Container>
      <Col xs="12" className="about-section">
        <h2>{props.language ? copy.en.header : copy.es.header}</h2>
        <h5 className="description">
          {props.language ? copy.en.description : copy.es.description}
        </h5>
      </Col>
    </Container>
  </div>
);

export default About;

const copy = {
  en: {
    header: 'About Me',
    description:
      'As a child, to avoid studying for math class, I programmed my TI-83 to do the math for me. As an adult, to avoid the rat race, I make amazing web applications and help entrepreneurs take their first steps. Lets make something awesome together.'
  },
  es: {
    header: 'Sobre Mi',
    description:
      'Como niño, para evitar estudiar para la clase de matemáticas, programo mi TI-83 para hacer las matemáticas para mí. Como un adulto, para evitar el mundo de los negocios, hago increíbles aplicaciones web y ayudar a los empresarios a dar sus primeros pasos. Vamos a hacer algo increíble juntos.'
  }
};

About.propTypes = {
  language: PropTypes.bool.isRequired
};
