import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import Copy from './FocusAreaCopy';

const FocusAreas = ({ language }) => {
  const lang = language ? Copy.en : Copy.es;
  return (
    <div id="focus" className="section">
      <Container className="focus-areas">
        <h2>{lang.title}</h2>
        <Row className="focus-logo-row">
          <Col md="4">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/mhooper722.appspot.com/o/icons%2Flaptop.svg?alt=media&token=d01a8c66-dd05-4c7c-bf37-0050c77480f5"
              alt={lang.web.altLogo}
            />
            <h4 className="focus-title">{lang.web.header}</h4>
            <p className="focus-p">{lang.web.subHeader}</p>
            <p className="focus-body-p">{lang.web.body}</p>
            <p className="focus-body-p">{lang.web.tldr}</p>
          </Col>
          <Col md="4">
            <img
              src="https://res.cloudinary.com/mhooper72/image/upload/v1516822290/g-suite_s9pzke.png"
              alt={lang.gmail.altLogo}
            />
            <h4 className="focus-title">{lang.gmail.header}</h4>
            <p className="focus-p">{lang.gmail.subHeader}</p>
            <p className="focus-body-p">{lang.gmail.body}</p>
            <p className="focus-body-p">{lang.gmail.tldr}</p>
          </Col>
          <Col md="4">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/mhooper722.appspot.com/o/icons%2Frocket-ship.svg?alt=media&token=f8064a30-3860-4eb5-9bae-026e577164b5"
              alt="Startup Launch Logo"
            />
            <h4 className="focus-title">{lang.startup.header}</h4>
            <p className="focus-p">{lang.startup.subHeader}</p>
            <p className="focus-body-p">{lang.startup.body}</p>
            <p className="focus-body-p"> {lang.startup.tldr}</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

FocusAreas.propTypes = {
  language: PropTypes.bool.isRequired
};

export default FocusAreas;
