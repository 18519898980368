import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CardDisplay from '../components/CardDisplay'

// function to setActiveClass
function setActiveClass(name) {
  return {
    activeClass: name,
  }
}

class MainDisplay extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: true,
      activeClass: 'work',
    }
  }
  // setting state functionally
  // TODO: RESOLVE THE NOOP CALLING SETSTATE ON AN UNMOUNTED COMPONENT
  handleSetActiveClass = e => {
    e.preventDefault()
    e.stopPropagation()
    const { name } = e.target
    const { activeClass } = this.state
    if (name !== activeClass) {
      this.setState(setActiveClass(name))
    }
  }

  render() {
    const { cards } = this.props.mainDisplay
    const { lifePhotos, workPhotos } = this.props
    const { activeClass, loaded } = this.state

    return (
      <div>
        <CardDisplay
          activeClass={activeClass}
          cards={cards}
          language={this.props.language}
          lifePhotos={lifePhotos}
          loaded={loaded}
          setActiveClass={this.handleSetActiveClass}
          workPhotos={workPhotos}
        />
      </div>
    )
  }
}

MainDisplay.propTypes = {
  language: PropTypes.bool.isRequired,
}

export default MainDisplay
