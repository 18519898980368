const copy = {
  en: {
    title: 'Focus Areas',
    web: {
      header: 'Web Developement',
      subHeader: '1, 2, 3 - Launch',
      body:
        'A set of intelligent apps to connect the people on your team, no matter where in the world they are. Fast, functional and redundant, G-Suite is the market-leading and most cost-effective productivity solution.',
      tldr:
        'tl:dr: A beautiful website in no time, delivered to you with a bow.',
      logoAlt: 'Web Development Logo'
    },
    gmail: {
      header: 'G-Suite',
      subHeader: 'GMail, Drive, Docs and More',
      body:
        "Launch with a strategy to match your unique capabilities and limitations as a young company. Let's craft agile support from launch to scale whether you're a startup, nonprofit, retailer or multinational operator.",
      tldr: 'tl:dr: Microsoft who? Your work in the cloud, in real-time.',
      logoAlt: 'G-Suite Logo'
    },
    startup: {
      header: 'Startup Launch',
      subHeader: 'Strategy, Operations and Launch',
      body:
        'Fast, fetching and functional websites deployed with the most modern frameworks. Mobile-first design with a striking desktop presence. Dynamic and scalable to grow with you whether your market is local or global.',
      tldr: `tl:dr: Launching a business is hard. I've done it.I can help.`,
      logoAlt: 'Startup Luanch Logo'
    }
  },
  es: {
    title: 'Áreas de Enfoque',
    web: {
      header: 'Desarrollador Web',
      subHeader: '1, 2, 3 - Launch',
      body:
        'Sitios web rápidos, atractivos y funcionales desplegados con los frameworks más modernos. Diseño móvil primero con una sorprendente presencia en el computadora. Dinámico y escalable para crecer con usted si su mercado es local o global.',
      tldr: 'tl:dr: tl: dr: Un hermoso sitio web ya, sólo filetes',
      logoAtl: 'Logotipo de desarrollo web'
    },
    gmail: {
      header: 'G-Suite',
      subHeader: 'GMail, Drive, Docs y Mas',
      body:
        'Todo lo que necesita para optimizar su trabajo, en un solo paquete que funciona a la perfección desde su computadora, teléfono o tablet. Diseñado para su lugar de trabajo, con un precio que comienza desde solo USD 5 por usuario al mes.',
      tldr: 'tl:dr: Microsoft, quién? Su trabajo en la nube, en tiempo real.',
      logoAlt: 'Logotipo de G-Suite'
    },
    startup: {
      header: 'Startup Launch',
      subHeader: 'ESTRATEGIA, LANZAMIENTO & OPERACIONES',
      body:
        'Lanzamiento con una estrategia para que coincida con sus capacidades y limitaciones únicas como una empresa joven. Diseñemos un soporte ágil desde el lanzamiento hasta la escala, ya sea un operador de lanzamiento, retail o multinacional.',
      tldr: `tl:dr: Lanzar un negocio es difícil. Lo he hecho. Puedo ayudarle.`,
      logoAlt: 'Logotipo de Startup'
    }
  }
};

export default copy;
