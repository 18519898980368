import React, { Component } from 'react'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import PropTypes from 'prop-types'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

// import faEnvelope from '@fortawesome/free-regular-svg-icons'
import mhooper72Logo from '../assets/icons/2018-mhooper72-logo-horizontal-gsuite.png'

class NavBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      width: 0,
      height: 0,
    }
  }

  closeMobileNav = e => {
    const { name } = e.target
    if (name === 'en' || name === 'es') {
      this.props.handleLanguage(name)
    }
    this.setState(() => ({
      isOpen: false,
    }))
  }

  toggle = e => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }))
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  render() {
    const { language, navBarTransparent } = this.props
    const navBarColor =
      navBarTransparent && this.state.width > 992 ? 'transparent' : 'light'
    return (
      <Navbar color={navBarColor} fixed="top" light expand="md">
        <AnchorLink to="/">
          <NavbarBrand style={{ cursor: 'pointer' }}>
            <img
              className="navbar-img"
              src={mhooper72Logo}
              alt="mhooper72 logo"
            />
          </NavbarBrand>
        </AnchorLink>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <AnchorLink onClick={this.closeMobileNav} to="#about">
                <NavLink>{language ? 'About' : 'Acerca'}</NavLink>
              </AnchorLink>
            </NavItem>
            <NavItem>
              <AnchorLink onClick={this.closeMobileNav} to="#work">
                <NavLink>{language ? 'Portfolio' : 'Portafolio'}</NavLink>
              </AnchorLink>
            </NavItem>
            <NavItem>
              <AnchorLink onClick={this.closeMobileNav} to="#focus">
                <NavLink>
                  {language ? 'Focus Areas' : 'Áreas de Enfoque'}
                </NavLink>
              </AnchorLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Connect
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  href="https://www.github.com/hoop71"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={this.closeMobileNav}
                >
                  {/* <FontAwesomeIcon icon={faCoffee} /> */}
                  GitHub
                </DropdownItem>
                <DropdownItem
                  href="https://www.linkedin.com/in/mhooper72"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={this.closeMobileNav}
                >
                  {/* <FontAwesomeIcon icon={faLinkedin} />  */}
                  LinkedIn
                </DropdownItem>
                <DropdownItem
                  href="https://www.instagram.com/mhooper71"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={this.closeMobileNav}
                >
                  {/* <FontAwesomeIcon icon={faInstagram} />  */}
                  Instagram
                </DropdownItem>
                <DropdownItem divider />
                <AnchorLink to="#contact" onClick={this.closeMobileNav}>
                  <DropdownItem>
                    {language ? 'Message' : 'Mensaje'}
                  </DropdownItem>
                </AnchorLink>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                {language ? 'English' : 'Español'}
              </DropdownToggle>
              <DropdownMenu right>
                {!language ? (
                  <DropdownItem onClick={this.closeMobileNav} name="en">
                    English
                  </DropdownItem>
                ) : null}
                {language ? (
                  <DropdownItem onClick={this.closeMobileNav} name="es">
                    Español
                  </DropdownItem>
                ) : null}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    )
  }
}

NavBar.propTypes = {
  handleLanguage: PropTypes.func.isRequired,
  language: PropTypes.bool.isRequired,
  navBarTransparent: PropTypes.bool.isRequired,
}

export default NavBar
