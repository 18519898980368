import React from 'react'
import Img from 'gatsby-image'

const PhotoHead = ({ photoHead }) => (
  <div className="photo-container">
    <Img fixed={photoHead.childImageSharp.fixed} alt="Matt Head Shot" />
  </div>
)

export default PhotoHead
