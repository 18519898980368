import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import PropTypes from 'prop-types'
import PhotoHead from './PhotoHead'

const PageHeader = ({ language, photoHead }) => (
  <div className="section">
    <Container className="page-header-wrapper">
      <Row className="content-center">
        <Col xs="12">
          <PhotoHead photoHead={photoHead} />
          <h3 className="title">Matt Hooper</h3>
          {language ? (
            <p>Full Stack Web Developer | Startup Launch</p>
          ) : (
            <p>Desarrollador Web | Startup Launch </p>
          )}
        </Col>
      </Row>
    </Container>
  </div>
)

PageHeader.propTypes = {
  language: PropTypes.bool.isRequired,
  photoHead: PropTypes.shape({}).isRequired,
}

export default PageHeader
