import React from 'react'
import { Container, Col, CardDeck } from 'reactstrap'
import PropTypes from 'prop-types'
import WorkCards from '../components/WorkCards/WorkCards'
import LifeCards from '../components/LifeCards'
import Pills from '../components/Pills'

const CardDisplay = props => {
  const {
    activeClass,
    cards: { work, life },
    language,
    lifePhotos,
    loaded,
    setActiveClass,
    workPhotos,
  } = props

  let DisplayCards

  // create WorkCards
  if (activeClass === 'work' && loaded) {
    DisplayCards =
      work &&
      work.map(card => (
        <Col lg={{ size: 6 }} xs="12" key={card.id}>
          <WorkCards
            language={language}
            className="card-deck-work active"
            alt={card.alt}
            imageURL={
              workPhotos.filter(
                el =>
                  el.node.childImageSharp.fluid.originalName === card.photoName
              )[0].node.childImageSharp.fluid
            }
            URL={card.URL}
            icons={card.icons}
          />
        </Col>
      ))
  }

  // create LifeCards
  if (activeClass === 'life' && loaded) {
    DisplayCards =
      life &&
      life.map(card => (
        <Col lg={{ size: 6 }} xs="12" key={card.id}>
          <LifeCards
            className="card-deck-life"
            language={language}
            alt={card.alt}
            imageURL={
              lifePhotos.filter(
                el =>
                  el.node.childImageSharp.fluid.originalName === card.photoName
              )[0].node.childImageSharp.fluid
            }
            URL={card.URL}
            title={card.title}
            coords={card.coords}
          />
        </Col>
      ))
  }

  return (
    <div id="work" className="section">
      <Container>
        <Col className="about-section">
          {language ? <h2>Work / Life</h2> : <h2>Trabajo / Vida</h2>}
          <Pills activeClass={activeClass} setActiveClass={setActiveClass} />
          {activeClass === 'work' && <CardDeck>{DisplayCards}</CardDeck>}
          {activeClass === 'life' && <CardDeck>{DisplayCards}</CardDeck>}
        </Col>
      </Container>
    </div>
  )
}

CardDisplay.propTypes = {
  language: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  cards: PropTypes.shape({}).isRequired,
  setActiveClass: PropTypes.func.isRequired,
  activeClass: PropTypes.string.isRequired,
}

export default CardDisplay
