import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import Narwhale from '../assets/icons/narwhale.png'

export default ({ language }) => (
  <div className="section">
    <Container className="footer">
      <Row>
        <Col sm={{ size: 6, offset: 3 }}>
          {`${language ? `made by a smart` : `hecho por un inteligente`}`}
          <img
            style={{ width: '70px', paddingBottom: '10px' }}
            src={Narwhale}
            alt="mhooper72 logo"
          />
          {`© ${new Date().getFullYear()}`}
        </Col>
      </Row>
    </Container>
  </div>
)
