import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { Card, CardFooter, Row, Col } from 'reactstrap'

const LifeCards = props => {
  const { imageURL, alt, URL, coords, className, title } = props
  return (
    <a href={URL} alt={alt} rel="noopener noreferrer" target="_blank">
      <Card className={className}>
        <Img fluid={imageURL} alt={alt} />
        <CardFooter>
          <Row>
            <Col xs="12">
              {coords}
              <br />
              {title}
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </a>
  )
}

LifeCards.propTypes = {
  imageURL: PropTypes.shape({}).isRequired,
  URL: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  coords: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
}

export default LifeCards
