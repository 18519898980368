import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import merge from 'lodash.merge'
import PropTypes from 'prop-types'
import {
  Alert,
  Button,
  Container,
  Row,
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faEnvelope } from '@fortawesome/free-solid-svg-icons'

// Encode message for netlify server https://www.netlify.com/blog/2017/07/20/how-to-integrate-netlifys-form-handling-in-a-react-app/
const encode = data =>
  Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')

const INITIAL_STATE = {
  name: {
    isFocused: false,
    message: '',
    isValid: null,
  },
  email: {
    isFocused: false,
    message: '',
    isValid: null,
    error: '',
  },
  message: {
    isFocused: false,
    message: '',
    isValid: null,
  },
  contactMessage: {
    type: null,
    open: false,
  },
}

class ContactUs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...INITIAL_STATE,
    }
  }

  onDismiss = () => {
    this.setState({ contactMessage: false })
  }

  onFocus = ({ target }) => {
    const { name } = target
    const newState = merge(
      {},
      { ...this.state },
      { [name]: { isFocused: true } }
    )
    this.setState(newState)
  }

  onBlur = ({ target }) => {
    const { name } = target
    const newState = merge(
      {},
      { ...this.state },
      { [name]: { isFocused: false } }
    )
    this.setState(newState)
  }

  validateEntry = e => {
    const { name } = e.target
    const {
      email: { message: email },
      name: { message: nameName },
      message: { message },
    } = this.state
    const reEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const reName = /^[a-z ,.'-]+$/i
    switch (name) {
      case 'email':
        if (email === '') {
          const newState = merge(
            {},
            { ...this.state },
            { email: { isValid: '' } }
          )
          this.setState(newState)
        } else if (reEmail.test(email)) {
          const newState = merge(
            {},
            { ...this.state },
            { email: { isValid: true } }
          )
          this.setState(newState)
        } else {
          const newState = merge(
            {},
            { ...this.state },
            { email: { isValid: false } }
          )
          this.setState(newState)
        }
        break
      case 'name':
        if (nameName === '') {
          const newState = merge(
            {},
            { ...this.state },
            { name: { isValid: '' } }
          )
          this.setState(newState)
        } else if (reName.test(name)) {
          const newState = merge(
            {},
            { ...this.state },
            { name: { isValid: true } }
          )
          this.setState(newState)
        } else {
          const newState = merge(
            {},
            { ...this.state },
            { name: { isValid: false } }
          )
          this.setState(newState)
        }
        break
      case 'message':
        if (message === '') {
          const newState = merge(
            {},
            { ...this.state },
            { message: { isValid: '' } }
          )
          this.setState(newState)
        } else {
          const newState = merge(
            {},
            { ...this.state },
            { message: { isValid: true } }
          )
          this.setState(newState)
        }
        break
      default:
        console.log(`Sorry, we are out of`)
    }
  }

  updateEmailIsValid = e => {
    this.setState({ email: { isValid: true } }, () => {
      this.validateEntry(e)
    })
  }

  handleSubmit = e => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'contact',
        name: this.state.name.message,
        email: this.state.email.message,
        message: this.state.message.message,
        language: this.props.language ? 'en' : 'es',
      }),
    })
      .then(() => {
        this.setState({
          ...INITIAL_STATE,
          contactMessage: { type: 'success', open: true },
        })
        setTimeout(() => {
          this.setState({ contactMessage: INITIAL_STATE.contactMessage })
        }, 6000)
      })
      .catch(() => {
        this.setState({
          ...INITIAL_STATE,
          contactMessage: { type: 'danger', open: true },
        })
        setTimeout(() => {
          this.setState({ contactMessage: INITIAL_STATE.contactMessage })
        }, 6000)
      })

    e.preventDefault()
  }

  handleChange = e => {
    e.persist()
    const { name, value } = e.target
    const newState = merge(
      {},
      { ...this.state },
      { [name]: { message: value } }
    )
    this.updateEmailIsValid(e)
    this.setState(newState)
  }

  render() {
    const { name, email, message, contactMessage } = this.state
    const { language } = this.props
    return (
      <StaticQuery
        query={graphql`
          query contactMessageFormQuery {
            site {
              siteMetadata {
                contactForm {
                  es {
                    message
                  }
                  en {
                    message
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <div id="contact" className="section">
            <Container className="contact-us">
              <h2>
                {language ? `What's Your Project?` : 'Cuál es su proyecto?'}
              </h2>
              <h5 className="description">
                {language ? "Let's do this!" : 'Hagámoslo!'}
              </h5>
              <Row>
                <Col md={{ size: 6, offset: 3 }}>
                  <Form
                    name="contact"
                    method="post"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={this.handleSubmit}
                  >
                    <input type="hidden" name="form-name" value="contact" />
                    <InputGroup
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      className={(name.isValid && 'is-active') || ''}
                    >
                      <InputGroupAddon addonType="prepend">
                        <FontAwesomeIcon icon={faUser} />
                      </InputGroupAddon>
                      <Input
                        required
                        type="text"
                        value={name.message}
                        name="name"
                        autoComplete="name"
                        onChange={this.handleChange}
                        placeholder={language ? 'Name' : 'Nombre'}
                      />
                    </InputGroup>
                    <InputGroup
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      className={(email.isValid && 'is-active') || ''}
                    >
                      <InputGroupAddon addonType="prepend">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroupAddon>
                      <Input
                        type="email"
                        value={email.message}
                        name="email"
                        autoComplete="email"
                        onChange={this.handleChange}
                        placeholder={language ? 'Email' : 'Correo'}
                      />
                    </InputGroup>
                    <InputGroup
                      className={(message.isValid && 'is-active') || ''}
                    >
                      <Input
                        type="textarea"
                        resize="none"
                        rows="4"
                        value={message.message}
                        name="message"
                        onChange={this.handleChange}
                        placeholder={
                          language ? 'Drop us a message' : 'Escribe un mensaje'
                        }
                      />
                      {/* <input type="hidden" name="_next" value="/redirect" /> */}
                    </InputGroup>
                    {contactMessage && Boolean(contactMessage.type) ? (
                      <Alert
                        color={contactMessage.type}
                        toggle={this.onDismiss}
                      >
                        {
                          data.site.siteMetadata.contactForm[
                            `${language ? 'en' : 'es'}`
                          ].message
                        }
                      </Alert>
                    ) : (
                      <React.Fragment>
                        <Button
                          id="submit-button"
                          className="submit-button"
                          size="lg"
                          block
                          type="submit"
                        >
                          {language ? 'Send' : 'Enviar'}
                        </Button>
                        <input
                          type="hidden"
                          name="_language"
                          value={language ? 'en' : 'es'}
                        />
                      </React.Fragment>
                    )}
                  </Form>
                </Col>
              </Row>
            </Container>
          </div>
        )}
      ></StaticQuery>
    )
  }
}

ContactUs.propTypes = {
  language: PropTypes.bool.isRequired,
}

export default ContactUs
