import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import NavBar from '../components/NavBar'
import MainDisplay from '../components/MainDisplay'
import PageHeader from '../components/PageHeader'
import About from '../components/About'
import FocusAreas from '../components/FocusAreas/FocusAreas'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import Favicon from '../assets/icons/favicon.ico'

import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/main.scss'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lang: true,
      navBarTransparent: true,
    }
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleResize)
  }

  scrollTop() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  }

  handleScroll = e => {
    const { scrollY } = window
    const isScrolled = scrollY < 400
    this.setState(
      {
        navBarTransparent: isScrolled,
      },
      () => this.state.navBarTransparent
    )
  }

  handleLanguage = name => {
    const { lang } = this.state
    if ((name === 'es' && lang) || (name === 'en' && lang === false)) {
      this.setState(prevState => ({
        lang: !prevState.lang,
      }))
    }
  }

  render() {
    const {
      data: {
        photoHead,
        site: {
          siteMetadata: {
            mainDisplay,
            index: { og, seo, title },
          },
        },
      },
    } = this.props
    const workPhotos = this.props.data.workPhotos.edges
    const lifePhotos = this.props.data.lifePhotos.edges
    const backgroundImage = this.props.data.site.siteMetadata.mainDisplay.cover
      .backgroundImage

    const { lang, navBarTransparent } = this.state
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{title}</title>
          <link rel="canonical" href="https://mhooper72.com" />
          <link rel="icon" type="image/png" href={Favicon} />
          {seo.map(item => (
            <meta key={item.type} name={item.type} content={item.content} />
          ))}
          {og.map(item => (
            <meta key={item.type} property={item.type} content={item.content} />
          ))}
        </Helmet>
        <div onScroll={this.handleScroll}>
          <NavBar
            language={lang}
            handleLanguage={this.handleLanguage}
            navBarTransparent={navBarTransparent}
            scrollTop={this.scrollTop}
          />
          <div className="wrapper">
            <div className="page-header">
              <div
                className="page-header-image"
                style={{
                  backgroundImage: `url(${backgroundImage})`,
                  maxHeight: '460px',
                  position: 'absolute',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center center',
                  width: '100%',
                  height: '100%',
                  zIndex: 1,
                }}
              />
              <PageHeader photoHead={photoHead} language={lang} />
            </div>
            <About language={lang} />
            <MainDisplay
              language={lang}
              lifePhotos={lifePhotos}
              mainDisplay={mainDisplay}
              workPhotos={workPhotos}
            />
            <FocusAreas language={lang} />
            <ContactUs language={lang} />
            <Footer language={lang} />
          </div>
        </div>
      </div>
    )
  }
}

export default App

export const query = graphql`
  query AppQuery {
    photoHead: file(relativePath: { eq: "mattHeader.jpg" }) {
      childImageSharp {
        fixed(width: 125, height: 125) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    workPhotos: allFile(filter: { sourceInstanceName: { eq: "work" } }) {
      edges {
        node {
          childImageSharp {
            fluid {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    lifePhotos: allFile(filter: { sourceInstanceName: { eq: "life" } }) {
      edges {
        node {
          childImageSharp {
            fluid {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    pageHeaderImage: allFile(filter: { relativePath: { eq: "cover.jpg" } }) {
      edges {
        node {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        index {
          title
          og {
            type
            content
          }
          seo {
            type
            content
          }
        }
        mainDisplay {
          cover {
            backgroundImage
          }
          cards {
            work {
              id
              alt
              icons {
                id
                alt
                image
              }
              photoName
              imageURL
              title
              URL
            }
            life {
              photoName
              id
              alt
              coords
              URL
              imageURL
              title
            }
          }
        }
      }
    }
  }
`
