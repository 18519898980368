import React from 'react'
import PropTypes from 'prop-types'
import { Nav, NavItem, NavLink } from 'reactstrap'
import Globe from '../assets/icons/newGlobe.png'
import Laptop from '../assets/icons/laptop.png'

const Pills = ({ activeClass, setActiveClass }) => {
  return (
    <div className="pills-container">
      <Nav pills>
        <NavItem className={activeClass === 'work' ? 'active' : ''}>
          <NavLink className="nav-pills" name="work" onClick={setActiveClass}>
            <img name="work" src={Laptop} alt="Logo" />
          </NavLink>
        </NavItem>
        <NavItem className={activeClass === 'life' ? 'active' : ''}>
          <NavLink className="nav-pills" name="life" onClick={setActiveClass}>
            <img src={Globe} alt="Logo" name="life" />
          </NavLink>
        </NavItem>
      </Nav>
    </div>
  )
}

Pills.propTypes = {
  setActiveClass: PropTypes.func.isRequired,
  activeClass: PropTypes.string.isRequired,
}

export default Pills
